<div class="footer">
  <div class="links">
    <span class="pipe"> | </span>
    <a class="mx-2"
       href="mailto:support@mvsc.com">Support</a> |
    <a class="mx-2"
       target="_blank"
       [href]="privacyPolicyUrl">Privacy Policy</a>
    |
    <div class="build-info">
      <ng-template #popTitle class="popover-header">Build Info & Statistics</ng-template>
      <ng-template #popContent>
        <div class="d-flex flex-column build-info">
          <div class="d-flex justify-content-between">
            <b>Version:</b> {{buildInfoVersion}}
          </div>
          <div class="d-flex justify-content-between">
            <b>Date:</b> {{buildInfoDate}}
          </div>
          <div class="d-flex justify-content-between">
            <b>Time:</b> {{buildTime}}
          </div>
          <div class="d-flex justify-content-between">
            <b>Server Execution Time:</b> {{serverExecutionTime$ | async}} ms
          </div>
        </div>
      </ng-template>
      <a class="mx-2"
         [ngbPopover]="popContent"
         [popoverTitle]="popTitle"
         [animation]="true"
         [placement]="isMobile ? 'top-right': 'auto'"
         popoverClass="statistics-popover">Build</a>
    </div>
  </div>
  <div class="copyright">
    <span class="mx-2">© {{ thisYearDate }}, Gallagher Bassett Services, Inc. All Rights Reserved</span>
  </div>
</div>
