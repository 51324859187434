<reporter-modal [show]="searchDialog"
                [noFooter]="true"
                title="Select Client"
                modalClasses="modal-lg"
                (onClose)="selectClient.emit(null)">
  <div role="modal-body">
    <div class="select-client mb-4">
      <form>
        <div class="form-group">
          <label for="searchTerm">Search for Clients {{ searchTerm }}</label>
          <div class="row mx-0">
            <div class="col pr-0">
              <input type="text"
                     name="searchTerm"
                     class="form-control"
                     id="searchTerm"
                     placeholder="Search for Clients by Client Number or Client Name"
                     [(ngModel)]="searchTerm">
            </div>
            <div class="col-auto pl-0">
              <button type="submit"
                      class="btn btn-action"
                      (click)="search()"
                      [disabled]="searchTerm?.length < 3">Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="clients.length > 0"
         class="search-results mx-0">
      <hr class="w-100">
      <div class="page-title mb-3">Select Client</div>
      <div class="results-list mb-2">
        <div class="row mx-0 mb-2 border-bottom"
             *ngFor="let client of clients">
          <div class="col">
            ({{ client.clientNumber }}) {{ client.clientName }}
          </div>
          <button class="btn btn-action btn-sm mr-1 mb-1 rounded-0"
                  (click)="selectClient.emit(client)">Select
          </button>
        </div>
      </div>
    </div>
  </div>
</reporter-modal>

<reporter-modal [show]="!searchDialog"
                title="Select Client"
                [displayCloseBtn]="false"
                [noFooter]="true"
                modalClasses="modal-lg">
  <div role="modal-body"
       class="select-client">
    <div *ngIf="clients.length > 0"
         class="search-results mx-0 mb-4">
      <div class="page-title mb-3">Select Client</div>
      <div class="results-list mb-2">
        <div class="row mx-0 mb-2 border-bottom"
             *ngFor="let client of clients">
          <div class="col">
            ({{ client.clientNumber }}) {{ client.clientName }}
          </div>
          <button type="button" class="btn btn-action btn-sm mr-1 mb-1 rounded-0"
                  (click)="selectClient.emit(client)">Select
          </button>
        </div>
      </div>
    </div>
  </div>
</reporter-modal>
