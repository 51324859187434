import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component ( {
  selector: 'reporter-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )

export class HeaderComponent {
  @Input () disableClientChange = false;
  @Input () whiteLabelLogoUri: string;
  @Output () logout = new EventEmitter ();
  @Output () loginAdmin = new EventEmitter ();
}
