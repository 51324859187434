<div class="reporter-app">
  <reporter-header [disableClientChange]="(canChangeClient | async) === false"
                   (logout)="logout()"
                   (loginAdmin)="loginAdmin()"
                   [whiteLabelLogoUri]="whiteLabelLogoUri">
  </reporter-header>
  <reporter-notifications *ngIf="showGlobalError"></reporter-notifications>
  <reporter-body>
    <router-outlet></router-outlet>
  </reporter-body>
  <reporter-footer></reporter-footer>
</div>
<reporter-loading-indicator *ngIf="loading"></reporter-loading-indicator>
