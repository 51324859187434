import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  AuthService,
  ClientService,
  UserService
} from 'app/shared/services';
import { Constants } from 'app/shared/config/constants';

@Component ( {
  selector: 'reporter-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: [ './user-menu.component.scss' ],
  encapsulation: ViewEncapsulation.None

} )
export class UserMenuComponent implements OnInit, AfterViewInit {

  @Output () logout = new EventEmitter ();
  @Output () loginAdmin = new EventEmitter ();
  @Input () disableClientChange;

  title = '';
  isWarningModalOpen = false;
  selectedClient;
  showClientsDialog = false;

  constructor (
    private userService: UserService,
    private clientService: ClientService,
    private authService: AuthService,
  ) {

  }

  ngOnInit (): void {
    const checkLoginSubscription = this.authService.loginChanged
      .subscribe ( ( res ) => {
        if ( res ) {
          setTimeout ( () => {
            if ( this.userService.userClients.length > 1 &&
              !this.isSystemUser &&
              !this.reporterClientSelected ) {
              this.showClientsDialog = true;
            }
            if ( this.userService.userClients.length === 1 ) {
              this.reporterClientSelected = true;
            }
          } );
        }
      } );
  }

  ngAfterViewInit () {
    setTimeout ( () => {
      this.showClientsDialog = !this.reporterClientSelected;
    } );
  }

  get currentUser () {
    return this.userService.currentUser;
  }

  get currentClient (): any {
    return this.userService.currentClient;
  }

  get clients () {
    return this.userService.userClients;
  }

  async selectClient ( client: any ) {
    this.userService.currentClient = client;
    await this.clientService.getBUSettings ();
    this.reporterClientSelected = true;
    this.reloadWindow ();
  }

  async selectTempClient ( client: any ) {
    this.showClientsDialog = false;
    if ( !client?.clientNumber ) {
      return;
    }
    await this.userService.addTempClient ( client );
    this.selectedClient = client;
    this.isWarningModalOpen = true;
  }

  reloadWindow (): void {
    window.location.reload ();
  }

  openWarningModal ( client ) {
    this.isWarningModalOpen = true;
    this.selectedClient = client;
  }

  get isAdminUser () {
    return this.userService.userHasPermission ( 'ADMIN_USER' );
  }

  get isLimitedAdminUser () {
    return this.userService.userHasPermission ( 'LIMITED_ADMIN' );
  }

  openAdmin () {
    if ( this.isAdminUser || this.isLimitedAdminUser ) {
      this.loginAdmin.emit ();
    }
  }

  openClientsDialog () {
    this.showClientsDialog = true;
  }

  get isSystemUser () {
    return this.userService.isSystemUser;
  }

  get reporterClientSelected () {
    return !this.authService.isAuthenticated ||
      this.isSystemUser ||
      localStorage.getItem ( Constants.localStorageKeys.reporterClientSelected );
  }

  set reporterClientSelected ( value ) {
    if ( !this.isSystemUser && value ) {
      localStorage.setItem ( Constants.localStorageKeys.reporterClientSelected, '1' );
    } else {
      localStorage.removeItem ( Constants.localStorageKeys.reporterClientSelected );
    }
  }

  dismissWarningModal () {
    this.isWarningModalOpen = false;
    this.selectedClient = null;
    if ( !this.reporterClientSelected ) {
      this.openClientsDialog ();
    }
  }
}
