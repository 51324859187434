<div class="reporter-header">
  <div class="reporter-page-title">
    <a [routerLink]="['/']">
      <img class="desktop"
           [src]="whiteLabelLogoUri">
    </a>
  </div>
  <div class="reporter-user-menu d-flex align-items-center">
    <reporter-user-menu [disableClientChange]="disableClientChange"
                        (logout)="logout.emit()"
                        (loginAdmin)="loginAdmin.emit()"></reporter-user-menu>
  </div>
</div>
