import {
  Component,
  OnInit
} from '@angular/core';
import { OidcAuthService } from 'app/shared/services';

@Component ( {
  selector: 'reporter-ping-preauth',
  templateUrl: './ping-preauth.component.html',
  styleUrls: [ './ping-preauth.component.scss' ]
} )
export class PingPreauthComponent implements OnInit {

  constructor( private oidcService: OidcAuthService ) {
  }

  ngOnInit(): void {
    this.oidcService.login ( {} );
  }

}
