import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component ( {
  selector: 'reporter-body',
  templateUrl: './body.component.html',
  styleUrls: [ './body.component.scss' ]
} )
export class BodyComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }

}
