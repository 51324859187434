import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  NgModule
} from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { FeaturesModule } from './features/features.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {
  ReporterCustomAdapter,
  ReporterCustomDateParserFormatter
} from 'app/shared/lib';
import { HttpErrorInterceptor } from 'app/shared/interceptors/HttpErrorInterceptor';
import {
  JwtHelperService,
  JwtModule
} from '@auth0/angular-jwt';
import { HttpLoadingInterceptor } from 'app/shared/interceptors/HttpLoadingInterceptor';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { initApp } from 'app/shared/lib/get-configuration';
import { CookieService } from 'ngx-cookie-service';
import { UiConfigService } from 'app/shared/services/ui-config.service';
import { initUiConfig } from 'app/shared/lib/get-ui-config';
import { Router } from '@angular/router';
import { Constants } from 'app/shared/config/constants';

export function tokenGetter() {
  return localStorage.getItem ( Constants.localStorageKeys.reporterAccessToken );
}

@NgModule ( { declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    exports: [
        SharedModule,
        FeaturesModule,
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: [
                    'localhost',
                    'build01',
                    'hqgbgofcbuild1'
                ],
                disallowedRoutes: [
                    /(api\/auth\/login)/,
                    /(api\/custom-ui-configs\/*)/
                ]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        FeaturesModule,
        ToastrModule.forRoot({
            toastClass: 'reporter-toast',
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
        }),
        InfiniteScrollModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initUiConfig,
            multi: true,
            deps: [
                HttpClient,
                UiConfigService,
                Router,
            ]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [
                HttpClient,
                ConfigurationService
            ]
        },
        {
            provide: NgbDateAdapter,
            useClass: ReporterCustomAdapter
        },
        {
            provide: NgbDateParserFormatter,
            useClass: ReporterCustomDateParserFormatter
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoadingInterceptor,
            multi: true
        },
        JwtHelperService,
        CookieService,
        provideHttpClient(withInterceptorsFromDi()),
    ] } )
export class AppModule {
}
