import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ClientService,
  UserService
} from 'app/shared/services';
import { take } from 'rxjs/operators';

@Component ( {
  selector: 'reporter-select-client-dialog',
  templateUrl: './select-client-dialog.component.html',
  styleUrls: [ './select-client-dialog.component.scss' ]
} )
export class SelectClientDialogComponent implements OnInit {
  @Input () searchDialog = true;
  @Output () selectClient = new EventEmitter ();
  searchTerm: string;
  clients = [];

  constructor(
    private clientService: ClientService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    if ( !this.searchDialog ) {
      this.clients = this.userService.userClients;
    }
  }

  search() {
    this.clientService.searchClients ( this.searchTerm )
      .pipe ( take ( 1 ) )
      .subscribe ( clients => {
        this.clients = clients;
      } );
  }
}
