import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'app/shared/services';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Constants } from 'app/shared/config/constants';
import { DateTime } from 'luxon';

@Component( {
  selector: 'reporter-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
} )
export class FooterComponent implements OnInit {

  readonly thisYearDate = new Date().getFullYear();

  constructor(
    private configService: ConfigurationService,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
  }

  serverExecutionTime$ = this.loadingIndicatorService.serverExecutionTime$;
  timeZoneShortCode;

  get privacyPolicyUrl(): string {
    return this.configService.byKey( 'privacy.policy.url' );
  }

  get buildInfoVersion(): string {
    return this.configService.byKey( 'build.info.version' );
  }

  get buildTime(): string {
    return this.parseTime( this.configService.byKey( 'build.info.time' ) );
  }

  get buildInfoDate(): string {
    return this.configService.byKey( 'build.info.date' );
  }

  get isMobile(): boolean {
    return window.innerWidth < Constants.mobileDeviceThreshold;
  }

  ngOnInit(): void {
    this.timeZoneShortCode = DateTime.fromISO( `${this.buildInfoDate}T${this.configService.byKey( 'build.info.time' )}`, { setZone: true } ).setZone( 'America/Phoenix' ).offsetNameShort;
  }

  parseTime( time: string ): string {
    const hour = time.substring( 0, 2 );
    const minutes = time.substring( 3, 5 );
    const seconds = time.substring( 6, 8 );

    return `${hour}:${minutes}:${seconds} ${this.timeZoneShortCode}`;
  }
}
